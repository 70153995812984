//
// Block
// --------------------------------------------------
.block {
  position: relative;
  width: 100%;
  padding: 60px 30px;
  color: $block-color;
  background-color: $block-bg;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.block-header {
  padding: 140px 30px 60px 30px;
  min-height: 300px;

  h2,
  p.lead {
    color:#fff;
  }
}
.block-overflow-hidden {
  overflow: hidden;
}
.block-bg-clip {
  position: absolute;
  top: -25px;
  right: 0;
  bottom: -25px;
  left: 0;
  overflow: hidden;
}
.block-bg {
  position: absolute;
  top: 25px;
  right: -50px;
  bottom: 25px;
  left: -50px;
  overflow: hidden;
  transform: rotate(-2deg);
}
.block-bg-img {
  position: absolute;
  top: -25px;
  bottom: -25px;
  right: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(2deg);
}
.block-inverse .block-bg-img {
  background-color: #000000;
}
.block-bg-img-top {
  background-position: top;
}
.block-bg-img-bottom {
  background-position: bottom;
}

.block-bordered{

  //border-top: 1px solid;
  //border-bottom: 1px solid;
  border-top-color: #ccc; // ie 8
  border-bottom-color: #ccc; // ie 8
  border-top-color: rgba(0, 0, 0, .1);
  border-bottom-color: rgba(0, 0, 0, .1);
}

.block-paralax {
  background-attachment: fixed;
}
.block-inverse {
  color: $block-color-inverted;
  background-color: $block-bg-inverted;
  //border-top:1px solid #d6dce0;
  //border-bottom:1px solid #d6dce0;

  &.block-bordered:before,
  hr {
    border-color: rgba(255, 255, 255, .2);
  }

  h1,h2,h3,h4,h5,h6,
  .h1,.h2,.h3,.h4,.h5,.h6 {
    color: #626365;
  }



  .btn-outline.btn-default,
  .btn-outline {
    &:focus,
    &.focus,
    &:hover,
    &:active,
    &.active,
    &.open > .dropdown-toggle {
      color: #000;

      &:hover,
      &:focus,
      &.focus {
        color: #000;
      }
    }
  }
}

.block-transparent {
  background-color: transparent;
}
.block-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & > iframe {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.block-foreground {
  position: relative;
  z-index: 2;
  display: inline-block;
}
.block-fill-height {
  display: table;
  width: 100%;
  height: 110vh;
}


.block-footer {
  background-color: $block-footer-bg;
  padding: 0px 0px;

  .line {
    width:60px;
    height:2px;
    background-color: #1C46A4;
  }

  .footer-bottom-bar {
    background-color: $block-footer-bottom-bg;

    a:link,
    a,
    p {
      color:#fff;
      @extend .small;

    }

    li.menu-item {
      @extend .list-inline-item;
    }
  }


}
// type
.block-label {
  margin-bottom: 20px;
  font-weight: 400;
  letter-spacing: 4px;
  color: $brand-primary;
  text-transform: uppercase;
}
.h1.block-title,
h1.block-title {
  font-size: $block-title-font-size;
  line-height: 1;
}
.block .lead {
  //font-size: $block-lead-font-size;
}
.block-angle {
  margin-bottom: -25px;
}

// block alignment
.block-xs-top,
.block-xs-bottom,
.block-xs-middle {
  display: table-cell;
  vertical-align: middle;
}
.block-xs-bottom {
  vertical-align: bottom;
}
.block-xs-top {
  vertical-align: top;
}

@media (min-width: $screen-sm-min) {
  .block-sm-top,
  .block-sm-bottom,
  .block-sm-middle {
    display: table-cell;
    vertical-align: middle;
  }
  .block-sm-bottom {
    vertical-align: bottom;
  }
  .block-sm-top {
    vertical-align: top;
  }
}
@media (min-width: $screen-md-min) {
  .block-md-top,
  .block-md-bottom,
  .block-md-middle {
    display: table-cell;
    vertical-align: middle;
  }
  .block-md-bottom {
    vertical-align: bottom;
  }
  .block-md-top {
    vertical-align: top;
  }
}

@media (min-width: $screen-lg-min) {
  .block-lg-top,
  .block-lg-bottom,
  .block-lg-middle {
    display: table-cell;
    vertical-align: middle;
  }
  .block-lg-bottom {
    vertical-align: bottom;
  }
  .block-lg-top {
    vertical-align: top;
  }
}

@media screen and (max-width: $screen-sm) {
  .block {
    padding: $block-vertical-padding 0;

    &:not(.block-secondary) .lead {
      font-size: $block-lead-font-size-sm;
    }
  }
  .h1.block-title,
  h1.block-title {
    font-size: $block-title-font-size-sm;
  }
}

//
// Block Content
//

.block-content {

}

  @media (max-width: $screen-sm) {

    .block-content .img-column {

      padding-left:0;
      padding-right:0;
    }

    .block-content .img-column img {
     margin-bottom: 40px;
      width: 100%;
    }

    @media (max-width: $screen-xs) {
      .block-content {
        padding-bottom:0px;
      }

      .block-content .img-column img {
        margin-top: 20px;
        margin-bottom: 0px;
        width: 100%;
      }
    }
  }


.block-callout {
  padding:0;

}
.block-content-steps {

  .steps-line:before {
    content: '';
    display: block;
    position: absolute;
    left: 32px;
    top: 0;
    height: 100%;
    border-left: 2px solid #ebedee;
  }

  .number {
    position: absolute;
    left: 12px;
    margin-top: 0px;
    width: 40px;
    height: 40px;

    overflow: hidden;
    background: #fff;
    border:2px solid $brand-primary;
    text-align: center;
    color: $brand-primary;
    line-height: 36px;
    font-size:1em;


  }

  h3 {
    padding-top: 6px;
  }

  .card-quote {
    background-color: #f2f4fa;
    border-color: #f2f4fa;

    padding:20px;

   .card-blockquote {
      color: #b2bec6;
    }
  }

  @media (max-width: $screen-sm) {
    .card-quote {
      margin-top:20px;
    }
  }
}

.block-content-usps {

  .card-usps {
    background-color: #063579;
    border-color: #063579;

    padding:20px;

    .li {
      color: #ffffff;
    }
  }

  @media (max-width: $screen-md) {
    .card-usps {
      padding:10px;

    }
  }
}

.block-show-work {

  padding:2px;
  background:#fafafa;

  border-top:1px solid #D8DEE2;
  border-bottom:1px solid #D8DEE2;



  .show-work-text {
    padding:60px 30px;
    background:#fafafa;
  }

  @media (max-width: $screen-sm) {
    .show-work-text {
      padding:40px 30px;
    }
  }

  .show-work-images{
    background:#ffffff;

    .card {
      position: relative;
      display: block;
      margin-bottom: 0rem;
      background-color: #fff;
      border-radius: 0rem;
      border: none;


    }

    .space-top {
      margin-top:2px;
    }

    .card-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-gap: .25rem;
      -moz-column-gap: .25rem;
      column-gap: .25rem;
    }

    .card-columns-small {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      -webkit-column-gap: .25rem;
      -moz-column-gap: .25rem;
      column-gap: .25rem;
    }

  }
}

.block-album {

  .card-columns-album {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;

    .card {

    }
  }

  @media (max-width: $screen-sm) {
    .card-columns-album {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      -webkit-column-gap: 1rem;
      -moz-column-gap: 1rem;
      column-gap: 1rem;
    }
  }


  //.grid-item { display:block; width: 25%; }
  //.grid-item--width2 { width: 50%; }


}


.block-pageblocks {
  padding:60px 0;



    .pageblock-item,
    .pageblock-item-projects {

      border: 1px solid #fff;

      position: relative;
      min-height: 200px;

      a.btn {
        position: absolute;
        bottom: 22px;
        z-index:100;
      }


    }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0,0,0,0.15);
  }

  @media (max-width: $screen-xs) {
    .pageblock-item,
    .pageblock-item-projects {
      border: 0px solid #fff;
      border-bottom: 2px solid #fff;

    }
  }

    .pageblock-item-projects {
      background-color: #063579;
      color: #ffffff;


      h3,
      .lead {
        color: #ffffff;
      }

    }
  }


.block-callout-one-column {

  background-color:$brand-primary;
  color:#ffffff;

  h2,
  p {
    color:#ffffff;
  }
}

.block-form {
  @extend .p-b-2;
}