// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

@import "_bootstrap_variables.scss";

// icons
$icon-font-path: "../fonts/";
$icon-font-name: "toolkit-entypo";

// Radius
$border-radius-base:    0px;
// Gray colors
$gray-base:    #000;
$gray-darker:  lighten($gray-base, 13.5%); // #222
$gray-dark:    lighten($gray-base, 20%);   // #333
$gray:         lighten($gray-base, 33.5%); // #555
$gray-light:   lighten($gray-base, 66.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee


// Brand Colors
$brand-primary: #063579;
$brand-success: #5cb85c;
$brand-info:    #FB423D;
$brand-warning: #f0ad4e;
$brand-danger:  #FF3167;


// text
$text-color:              #8a8f93;
$headings-color:          #626365;
$letter-spacing-base:     -.02em;
$font-size-base:          14px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 2.1)) !default; // ~36px
$font-size-h2:            floor(($font-size-base * 1.9)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base * 1.5)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

// Spacing
$spacer:            20px;
$spacer-x:          $spacer;
$spacer-y:          $spacer;
$grid-gutter-width: 20px;


// Typography
$font-family-sans-serif: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:      "Lora", Georgia, "Times New Roman", Times, serif;
$font-weight-base:       300;
$line-height-base:       1.6;


// For h1-h6
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-small-color: inherit;


// For .lead
$lead-font-size:      1.2rem;
$lead-font-size-sm:   floor(($font-size-base * 1.45));
$lead-font-weight:    300;
$lead-line-height:    1.6;
$lead-letter-spacing: 0;


// Buttons
$btn-font-size:      16px;
$btn-font-weight:    700;
$btn-text-transform: none;
$btn-letter-spacing: normal;

$btn-toolbar-divider-bg: $gray-lighter;


$btn-cta-color:            #fff !default;
$btn-cta-bg:               #FB423D !default;
$btn-cta-border:           #AB3A3F !default;


// Container sizes
$container-desktop:       (860px + $grid-gutter-width);
$container-md:            $container-desktop;
$container-large-desktop: (1000px + $grid-gutter-width);
$container-lg:            $container-large-desktop;


// nav-bordered
$nav-bordered-color:              #fff;
$nav-bordered-color-active:       $brand-primary;
$nav-bordered-font-weight:        700;
$nav-bordered-font-weight-active: 700;


// nav header
$nav-header-color: lighten($gray, 35%);


// Navbar
$navbar-default-bg:                #fff;
$navbar-default-link-active-color: #333;
$navbar-default-link-active-bg:    transparent;
$navbar-nav-active-font-weight:    inherit;


// Navbar - transparent
$navbar-transparent-color:               #fff;
$navbar-transparent-bg:                  transparent;
$navbar-transparent-border:              transparent;
$navbar-transparent-link-color:          $navbar-transparent-color;
$navbar-transparent-link-hover-color:    darken($navbar-transparent-color, 10%);
$navbar-transparent-link-hover-bg:       transparent;
$navbar-transparent-link-active-color:   $navbar-transparent-link-hover-color;
$navbar-transparent-link-active-bg:      transparent;
$navbar-transparent-link-disabled-color: lighten($navbar-transparent-color, 10%);
$navbar-transparent-link-disabled-bg:    transparent;
$navbar-transparent-brand-color:         $navbar-transparent-color;
$navbar-transparent-brand-hover-color:   darken($navbar-transparent-color, 10%);
$navbar-transparent-brand-hover-bg:      transparent;
$navbar-transparent-toggle-hover-bg:     fade($navbar-transparent-color, 10%);
$navbar-transparent-toggle-icon-bar-bg:  $navbar-transparent-color;
$navbar-transparent-toggle-border-color: $navbar-transparent-color;


// Jumbotron
$jumbotron-heading-font-size: ceil(($font-size-base * 4.5));


// Block
$block-color:              #8a8f93;
$block-bg:                 #fff;
$block-color-inverted:     #8a8f93;
$block-bg-inverted:        #fafafa;
$block-title-font-size-sm: floor(($font-size-base * 4));
$block-title-font-size:    floor(($font-size-base * 3));
$block-lead-font-size-sm:  floor(($font-size-base * 1.8));
$block-lead-font-size:     floor(($font-size-base * 1.6));
$block-vertical-padding:   60px;
$block-footer-bg:          #f2f4fa;
$block-footer-bottom-bg:   #14347C;

// Callouts
$callout-padding:       60px 30px;
$callout-border-radius: $border-radius-base;
$callout-seconday-text-color: #fff;


// Stage
$stage-bg:                    $body-bg;
$stage-shelf-width:           250px;
$stage-toggle-offset:         20px;
$stage-toggle-zindex:         100;
$stage-toggle-padding:        4px 12px;
$stage-toggle-color:          #fff;
$stage-toggle-bg:             rgba(0,0,0,.1);
$stage-toggle-border-radius:  $border-radius-base;
$stage-toggle-hover-color:    #fff;
$stage-toggle-hover-bg:       $link-color;


// zoom
$zoom-overlay-bg: #000;
$zindex-zoom: 1080;


// Custom forms
$custom-select-color:       $input-color;
$custom-select-bg:          $input-bg;
$custom-select-disabled-bg: $input-bg-disabled;

$custom-control-indicator-bg:     $input-bg;
$custom-control-indicator-border: rgba(0,0,0,.15);

$custom-control-indicator-checked-bg:      $brand-primary;
$custom-control-indicator-checked-border:  $brand-primary;
$custom-control-indicator-active-bg:       lighten($brand-primary, 15%);
$custom-control-indicator-active-border:   lighten($brand-primary, 15%);
$custom-control-indicator-disabled-bg:     darken($input-bg, 5%);
$custom-control-indicator-disabled-border: $custom-control-indicator-border;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
$screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md-min:              $screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);