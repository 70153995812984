// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


// GRAVITY FORMS -> BOOTSTRAP 4

.gform_fields {
  @extend .list-unstyled;
  @extend .row;

  input, select, textarea {
    @extend .form-control;
  }

  textarea {
    height: auto;
  }

}

.gfield_description {

}

.validation_error {
  margin-bottom: .5rem;
  color: #ff3167;
  @extend .form-control-feedback;
}

.validation_message {
  @extend .text-xs-center;
  @extend .form-control-feedback;
}

.gform_button {
  @extend .btn;
}

.gfield_required {
  color: $alert-danger-text;
}

.gform_wrapper ul.gfield_radio li, .gform_wrapper ul.gfield_checkbox li {
  @extend .form-check;
}

.gform_validation_container {
  display: none;
}



.gform_validation_error .gform_fields {
  //@extend .has-error;
}

.gform_validation_error .gfield_error {
  @extend .has-danger;
}

.gform_validation_error .ginput_container input {
  //@extend .form-control-success;

}

.gform_validation_error .gfield_error .ginput_container input {
  @extend .form-control-danger;

}


#gform_1 > .gform_footer {
  display: inline-block;
  padding: 0em;
  @extend .col-md;
  @extend .pull-md-left;

}

#gform_1 .gform_body {
  display: inline-block;
  float: left;
  margin-left:.1rem;
  @extend .col-md;
  @extend .offset-md-3;

}


#gform_2 .gform_body,
#gform_2 .gform_body .gform_fields_2 li.gfield {
  display:block;

  float: left;
  @extend .col-md-10;

}

#gform_2 > .gform_footer {
  display: inline-block;
  padding: 0em;
  @extend .col-md-10;

}

#gform_2 li {
  display:block;

  @extend .col-md-8;
  @extend .m-b-2;
  @extend .p-l-0;
  @extend .m-l-0;

}

#gform_2 .validation_message {
  @extend .text-xs-left;
  @extend .form-control-feedback;
}

#gform_wrapper_1 {
  @extend .m-x-auto;
}


#gform_1 li {

  background-color:transparent;
  padding:0;
  border:0px solid #fff;
  padding:0px 1px;


  .gfield_label {
    display: none;
  }
}


#gforms_confirmation_message_1 {

  @extend .col-md-12;
  @extend .text-xs-center;
  @extend .lead;
  @extend .m-x-auto;
  @extend .has-success;


}

