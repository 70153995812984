.iconlist {
  list-style: none;
  padding-left: 0;

  > li {
    padding: 4px 0 2px 25px;

    > .icon {
      float: left;
      margin-left: -25px;
    }
  }

  > .active {
    color: $brand-primary;

    .icon {
      opacity: 1;
    }
  }
}

.thick {
  > li .icon {
    color:#97EB3B;
  }
}
