img[data-action="zoom"] {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
}
.zoom-img,
.zoom-img-wrap {
  position: relative;
  z-index: $zindex-zoom;
  transition: all 300ms;
}
img.zoom-img {
  cursor: pointer;
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
}
.zoom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ($zindex-zoom - 1);
  background: $zoom-overlay-bg;
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms;
}
.zoom-overlay-open .zoom-overlay {
  filter: "alpha(opacity=100)";
  opacity: 1;
}
.zoom-overlay-open,
.zoom-overlay-transitioning {
  cursor: default;
}

// reset media body's overflow to visible, for image zoom
.media,
.media-body {
  overflow: visible;
}
