.menu-item {
  @extend .nav-item;

}


.navbar-nav .menu-item+.menu-item, .navbar-nav .menu-item+.nav-item, .navbar-nav .nav-item+.menu-item, .navbar-nav .nav-item+.nav-item, .navbar-nav .nav-link+.nav-link {
  margin-left: .8rem;
}


.navbar-top {

  padding:.55rem;

  a {

    font-size:.85rem;

    color:#7D8995;
  }

  .header-call-us {

    a {
      font-weight: 800;
      color: #14357F;
    }
  }

}

.main-menu {

  padding:0;

  .navbar-nav {
    height: 56px;
    line-height: 56px;
    margin-top: .55rem;


  }

  a {
    color:$brand-primary;
    padding:.55rem;
  }

  li:hover a,
  li.active a {
    text-decoration: none;
    color:#ffffff;
    background-color: #1C46A4;
  }

}

.hamburger {

  background-color: #fff;
  //border:1px solid $brand-primary;
  padding: 8px 8px 8px 8px;
  margin-top: .50rem;

  width: 56px;
  height:56px;

  .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {

    background-color: $brand-primary;
    //width: 30px;
  }

  span.hamburger-box {
    margin-top:4px;

  }


}

.btn-call-us {
  width: 56px;
  height:56px;
  padding: 6px;
  margin: .50rem;

  background: #fff;
  //border:1px solid $brand-primary;

  span {
    font-size: 2rem;
    color:$brand-primary;
    text-align: center;

  }
}


.mm-listview>li.active {
  background-color:$brand-primary;
  color:#fff;
}

.mm-listview>li:hover {
  background-color:$brand-primary;
  color:#fff;

}

#header-menu:not( .mm-menu ) {
  display: none;
}