
*,
*:before,
*:after {
  box-sizing: border-box;
}

/* Container
---------------------------------------------------------------------- */

.containermix {
  padding: 1rem ;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
}

/* Target Elements
---------------------------------------------------------------------- */

.mix,
.gap {
  display: inline-block;

}

.mix {
  background: #fff;
  border-radius: 2px;
  margin-bottom: 1rem;
  position: relative;
}

.mix:before {
  content: '';
  //display: inline-block;
  //padding-top: 56.25%;
}

/* Grid Breakpoints
---------------------------------------------------------------------- */
/* Grid Breakpoints
---------------------------------------------------------------------- */

/* 2 Columns */

.mix,
.gap {
  width: calc(100%/3 - (((3 - 1) * 1rem) / 3));
}

/* 2 Columns */

@media screen and (max-width: 541px) {
  .mix,
  .gap {
    width: calc(100%/1 - (((1 - 1) * 1rem) / 1));
  }
}

@media screen and (min-width: 541px) {
  .mix,
  .gap {
    width: calc(100%/2 - (((2 - 1) * 1rem) / 2));
  }
}

/* 4 Columns */

@media screen and (min-width: 961px) {
  .mix,
  .gap {
    width: calc(100%/3 - (((3 - 1) * 1rem) / 3));
  }
}

/* 5 Columns */

@media screen and (min-width: 1281px) {
  .mix,
  .gap {
    width: calc(100%/3 - (((3 - 1) * 1rem) / 3));
  }
}

button.mixitup-control-active {
  @extend .active;
}