//
// Callouts
//

// Base styles
.callout {
  //position: relative;
  //display: flex;
  //width: 100%;
  //margin-bottom: $line-height-computed;

  color: $text-color;
  background-color: #fff;
  border-radius: $callout-border-radius;
  padding:2px;

  border: 1px solid rgba(178,190,198,0.50);
}

// Improve alignment and spacing of inner content
.callout-content {

  background: rgba(28,70,163,0.06);


  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }
}

.callout-content,
.callout-secondary {

  padding: $callout-padding;
  position: relative;

}

.callout-secondary-container {
  position: relative;
  z-index:11;
}
.callout-secondary .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(6,52,121,0.80);
}

@media (max-width: $screen-sm) {
  .callout-content,
  .callout-secondary {

    padding: 40px 10px;


  }
}

.callout-secondary {
  background-color: #1C46A4;
  color:$callout-seconday-text-color;
}


@media (max-width: $screen-md)
{

  .callout-content input {
    margin-bottom:8px;
  }

}

@media (max-width: $screen-sm)
{

  .callout-secondary{

    padding: 40px 10px;

  }

}